<template>
  <div id="bsModalBankAddressBook" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="currency" rules="required" tag="div">
                <label for="p-currency">Currency</label>
                <select v-if="editIndex === null" id="p-currency" v-model="form.currency" class="form-control"
                  @change="selectCurrency()">
                  <option v-for="(currency, index) in targetCurrencies" :key="index" :value="currency">{{ currency }}
                  </option>
                </select>
                <input v-if="editIndex !== null" v-model="form.currency" aria-label="" class="form-control" readonly
                  type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="form.currency" v-slot="{ errors }" class="form-group" name="address"
                rules="required" tag="div">
                <label v-if="form.currency">Select recipient type</label>
                <div v-if="form.currency">
                  <label>
                    <input v-model="form.targetLegalType" type="radio" value="PRIVATE" /> Personal
                  </label>
                  &emsp;
                  <label v-if="isBusiness">
                    <input v-model="form.targetLegalType" type="radio" value="BUSINESS" /> Business
                  </label>
                </div>
                <span v-if="form.currency" class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="form.currency" v-slot="{ errors }" class="form-group" name="account holder name"
                rules="required" tag="div">
                <label v-if="form.currency" for="accountHolderName">Account holder name</label>
                <input v-if="form.currency" id="accountHolderName" v-model="form.accountHolderName" class="form-control"
                  type="text" />
                <span v-if="form.currency" class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('IBAN')" v-slot="{ errors }" class="form-group" name="IBAN"
                rules="required" tag="div">
                <label for="IBAN">IBAN</label>
                <input id="IBAN" v-model="form.details.IBAN" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('cardNumber')" v-slot="{ errors }" class="form-group"
                name="card number" rules="required" tag="div">
                <label for="cardNumber">Card Number</label>
                <input id="cardNumber" v-model="form.details.cardNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('taxId')" v-slot="{ errors }" class="form-group" name="taxId"
                rules="required" tag="div">
                <label for="taxId">Tax Id: CUIL / CUIT</label>
                <input id="taxId" v-model="form.details.taxId" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('bsbCode')" v-slot="{ errors }" class="form-group" name="bsb code"
                rules="required" tag="div">
                <label for="bsbCode">BSB code</label>
                <input id="bsbCode" v-model="form.details.bsbCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('bankCode')" v-slot="{ errors }" class="form-group" name="bank code"
                rules="required" tag="div">
                <label for="bankCode">Bank code</label>
                <select id="bankCode" v-model="form.details.bankCode" class="form-control" @change="getBranches()">
                  <option v-for="(bank, index) in banks" :key="index" :value="bank.code">
                    {{ bank.title + " [" + bank.code + "]" }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="form.currency === 'BRL' && isRequired('branchCode')" v-slot="{ errors }"
                class="form-group" name="branch code" rules="required" tag="div">
                <label for="branchCode">Branch code</label>
                <input id="branchCode" v-model="form.details.branchCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="form.currency !== 'BRL' && isRequired('branchCode')" v-slot="{ errors }"
                class="form-group" name="branch name" rules="required" tag="div">
                <label for="brchCode">Branch name</label>
                <select id="brchCode" v-model="form.details.branchCode" class="form-control">
                  <option v-for="(branch, index) in branches" :key="index" :value="branch.code">{{ branch.title }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('institutionNumber')" v-slot="{ errors }" class="form-group"
                name="institution number" rules="required" tag="div">
                <label for="institutionNumber">Institution number</label>
                <input id="institutionNumber" v-model="form.details.institutionNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('transitNumber')" v-slot="{ errors }" class="form-group"
                name="transit number" rules="required" tag="div">
                <label for="transitNumber">Transit number</label>
                <input id="transitNumber" v-model="form.details.transitNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('prefix')" v-slot="{ errors }" class="form-group" name="prefix"
                rules="required" tag="div">
                <label for="prefix">Prefix</label>
                <input id="prefix" v-model="form.details.prefix" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('sortCode')" v-slot="{ errors }" class="form-group" name="sort code"
                rules="required" tag="div">
                <label for="sortCode">UK Sort code</label>
                <input id="sortCode" v-model="form.details.sortCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('ifscCode')" v-slot="{ errors }" class="form-group" name="ifsc code"
                rules="required" tag="div">
                <label for="ifscCode">IFSC code</label>
                <input id="ifscCode" v-model="form.details.ifscCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('abartn')" v-slot="{ errors }" class="form-group" name="abartn"
                rules="required" tag="div">
                <label for="abartn">Routing number</label>
                <input id="abartn" v-model="form.details.abartn" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('swiftCode') && selectedCurrency !== 'MYR'" v-slot="{ errors }"
                class="form-group" name="swift code" rules="required" tag="div">
                <label for="swiftCode">Swift code</label>
                <input id="swiftCode" v-model="form.details.swiftCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('swiftCode') && selectedCurrency === 'MYR'" v-slot="{ errors }"
                class="form-group" name="bank name" rules="required" tag="div">
                <label for="swiftCodeMYR">Beneficiary's bank name</label>
                <select id="swiftCodeMYR" v-model="form.details.swiftCode" class="form-control">
                  <option v-for="(bank, index) in banks" :key="index" :value="bank.code">
                    {{ bank.title + " [" + bank.code + "]" }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('clabe')" v-slot="{ errors }" class="form-group" name="clabe"
                rules="required" tag="div">
                <label for="clabe">Recipient CLABE Number</label>
                <input id="clabe" v-model="form.details.clabe" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('accountType')" v-slot="{ errors }" class="form-group"
                name="account type" rules="required" tag="div">
                <label for="accountType">Account type</label>
                <select id="accountType" v-model="form.details.accountType" class="form-control">
                  <option v-if="selectedCurrency === 'JPY'" value="CURRENT">Current</option>
                  <option value="CHECKING">Checking</option>
                  <option value="SAVING">Saving</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('accountNumber')" v-slot="{ errors }" class="form-group"
                name="account number" rules="required" tag="div">
                <label for="accountNumber">Account Number</label>
                <input id="accountNumber" v-model="form.details.accountNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('idDocumentType')" v-slot="{ errors }" class="form-group"
                name="id document type" rules="required" tag="div">
                <label for="idDocumentType">ID document type</label>
                <input id="idDocumentType" v-model="form.details.idDocumentType" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('idDocumentNumber')" v-slot="{ errors }" class="form-group"
                name="id document number" rules="required" tag="div">
                <label for="idDocumentNumber">Beneficiary's identification number</label>
                <input id="idDocumentNumber" v-model="form.details.idDocumentNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('rut')" v-slot="{ errors }" class="form-group" name="rut"
                rules="required" tag="div">
                <label for="rut">Beneficiary's RUT number (Rol Único Tributario)</label>
                <input id="rut" v-model="form.details.rut" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('cpf')" v-slot="{ errors }" class="form-group" name="cpf"
                rules="required" tag="div">
                <label for="cpf">Recipient tax ID/CPF</label>
                <input id="cpf" v-model="form.details.cpf" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('phoneNumber')" v-slot="{ errors }" class="form-group"
                name="phone number" rules="required" tag="div">
                <label for="phoneNumber">Phone Number</label>
                <input id="phoneNumber" v-model="form.details.phoneNumber" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('russiaRegion')" v-slot="{ errors }" class="form-group" name="region"
                rules="required" tag="div">
                <label for="russiaRegion">Region</label>
                <select id="russiaRegion" v-model="form.details.russiaRegion" class="form-control">
                  <option v-for="(region, index) in regions" :key="index" :value="region.code">{{ region.title }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('country')" v-slot="{ errors }" class="form-group" name="country"
                rules="required" tag="div">
                <label for="country">Country</label>
                <select id="country" v-model="form.details.address.country" class="form-control">
                  <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('state')" v-slot="{ errors }" class="form-group" name="state"
                rules="required" tag="div">
                <label for="state">State</label>
                <input id="state" v-model="form.details.address.state" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('city')" v-slot="{ errors }" class="form-group" name="city"
                rules="required" tag="div">
                <label for="city">City</label>
                <input id="city" v-model="form.details.address.city" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('postCode')" v-slot="{ errors }" class="form-group" name="post code"
                rules="required" tag="div">
                <label for="postCode">Post Code</label>
                <input id="postCode" v-model="form.details.address.postCode" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isRequired('firstLine')" v-slot="{ errors }" class="form-group" name="address"
                rules="required" tag="div">
                <label for="firstLine">Address</label>
                <input id="firstLine" v-model="form.details.address.firstLine" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  Save
                  <span v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                  </span>
                </button>
              </div>
            </form>
          </ValidationObserver>

          <div v-if="isAvailable" class="row mt-8" style="max-height: 400px; overflow-y:  auto;">
            <div class="col-sm-12">
              <div v-for="(transferwiseAddresses, key) in addresses.transferwise" :key="key">
                <div v-if="transferwiseAddresses.length > 0">
                  <hr />
                  <h5>{{ key }}</h5>
                  <div v-for="(address, index) in transferwiseAddresses" :key="index">
                    <div class="row">
                      <div class="col-sm-12">
                        <p class="mb-0">Target currency code: {{ address.currency }}</p>
                        <p class="mb-0">Account holder name: {{ address.accountHolderName }}</p>
                        <div class="mb-8">
                          <p v-for="(detail, i) in address.details" :key="i" :class="{ 'd-none': isObject(detail) }"
                            class="mb-0">
                            {{ format(i) + ": " + detail }}
                          </p>
                          <div v-if="address.details.address">
                            <p v-for="(addr, i) in address.details.address" :key="i" class="mb-0">
                              {{ format(i) + ": " + addr }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <button v-if="!isSelector" :class="address.default ? 'btn-danger' : 'btn-success'"
                          class="btn btn-sm btn-block btn-flat" type="button" @click="toggleAsDefault(address, key)">
                          <span v-if="!address.default">Select As Default</span>
                          <span v-if="address.default">Remove As Default</span>
                        </button>
                        <button v-if="isSelector" class="btn btn-success btn-sm btn-block btn-flat" type="button"
                          @click="selectAddress(address)">
                          Select
                        </button>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-success btn-sm btn-block btn-flat" type="button"
                          @click="populateForm(address, index, key)">
                          Edit
                        </button>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-danger btn-sm btn-block btn-flat" type="button"
                          @click="deleteAddress(index, key)">Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification } from "@/lib/utils";
import axios from "axios";

export default {
  name: "BankAddressBookModal",
  data() {
    return {
      isLoading: false,
      title: "",
      user: null,
      addresses: {
        crypto: {},
        transferwise: {},
      },
      selectedCurrency: "",
      required: [],
      validatorObj: {},
      targetCurrencies: [
        "AED", "ARS", "AUD", "BDT", "BGN", "BRL", "CAD", "CHF", "CLP", "CNY",
        "CZK", "DKK", "EGP", "EUR", "GBP", "GEL", "GHS", "HKD", "HRK",
        "HUF", "IDR", "ILS", "INR", "JPY", "MYR", "KES", "LKR", "MAD",
        "MXN", "MYR", "NGN", "NOK", "NPR", "NZD", "PEN", "PHP", "PKR", "PLN",
        "RON", "RUB", "SEK", "SGD", "THB", "TRY", "TZS", "UAH", "USD",
        "VND", "ZAR",
      ],
      labels: {
        ARS: {
          accountNumber: "Account number (CBU)",
        },
        BRL: {
          accountNumber: "Account number with security digit",
        },
        IDR: {
          accountNumber: "Account number (IDR accounts only)",
        },
        PHP: {
          accountNumber: "Account number (PHP accounts only)",
        },
        UAH: {
          accountNumber: "Last 4 digits of UAH PrivatBank card",
          phoneNumber: "Use phone number registered in Privat Bank",
        },
        RUB: {
          bankCode: "BIK (Bank Code)",
        },
        GBP: {
          accountNumber: "Account number/IBAN",
        },
        HUF: {
          IBAN: "IBAN/Local Account Number",
        },
        MYR: {
          swiftCode: "BIC/SWIFT code",
        },
        NGN: {
          accountNumber: "Account Number/NUBAN",
        },
        SEK: {
          IBAN: "IBAN/Bankgiro number",
        },
      },
      banks: [],
      branches: [],
      regions: [
        { title: "Adygea", code: "ADYGEA" },
        { title: "Altai Krai", code: "ALTAIKRAI" },
        { title: "Altai Republic", code: "ALTAIREPUBLIC" },
        { title: "Amur Oblast", code: "AMUROBLAST" },
        { title: "Arkhangelsk Oblast", code: "ARKHANGELSKOBLAST" },
        { title: "Astrakhan Oblast", code: "ASTRAKHANOBLAST" },
        { title: "Bashkortostan", code: "BASHKORTOSTAN" },
        { title: "Belgorod Oblast", code: "BELGORODOBLAST" },
        { title: "Bryansk Oblast", code: "BRYANSKOBLAST" },
        { title: "Buryatia", code: "BURYATIA" },
        { title: "Chechen Republic", code: "CHECHENREPUBLIC" },
        { title: "Chelyabinsk Oblast", code: "CHELYABINSKOBLAST" },
        { title: "Chukotka Autonomous Okrug", code: "CHUKOTKAAUTONOMOUSOKRUG" },
        { title: "Chuvash Republic", code: "CHUVASHREPUBLIC" },
        { title: "Crimea", code: "CRIMEA" },
        { title: "Dagestan", code: "DAGESTAN" },
        { title: "Ingushetia", code: "INGUSHETIA" },
        { title: "Irkutsk Oblast", code: "IRKUTSKOBLAST" },
        { title: "Ivanovo Oblast", code: "IVANOVOOBLAST" },
        { title: "Jewish Autonomous Oblast", code: "JEWISHAUTONOMOUSOBLAST" },
        { title: "Kabardino-Balkar Republic", code: "KABARDINO" },
        { title: "Kaliningrad Oblast", code: "KALININGRADOBLAST" },
        { title: "Kalmykia", code: "KALMYKIA" },
        { title: "Kaluga Oblast", code: "KALUGAOBLAST" },
        { title: "Kamchatka Krai", code: "KAMCHATKAKRAI" },
        { title: "Karachay-Cherkess Republic", code: "KARACHAY" },
        { title: "Karelia", code: "KARELIA" },
        { title: "Kemerovo Oblast", code: "KEMEROVOOBLAST" },
        { title: "Khabarovsk Krai", code: "KHABAROVSKKRAI" },
        { title: "Khakassia", code: "KHAKASSIA" },
        { title: "Khanty–Mansi Autonomous Okrug – Yugra", code: "KHANTY" },
        { title: "Kirov Oblast", code: "KIROVOBLAST" },
        { title: "Komi Republic", code: "KOMIREPUBLIC" },
        { title: "Kostroma Oblast", code: "KOSTROMAOBLAST" },
        { title: "Krasnodar Krai", code: "KRASNODARKRAI" },
        { title: "Krasnoyarsk Krai", code: "KRASNOYARSKKRAI" },
        { title: "Kurgan Oblast", code: "KURGANOBLAST" },
        { title: "Kursk Oblast", code: "KURSKOBLAST" },
        { title: "Leningrad Oblast", code: "LENINGRADOBLAST" },
        { title: "Lipetsk Oblast", code: "LIPETSKOBLAST" },
        { title: "Magadan Oblast", code: "MAGADANOBLAST" },
        { title: "Mari El Republic", code: "MARIELREPUBLIC" },
        { title: "Mordovia", code: "MORDOVIA" },
        { title: "Moscow", code: "MOSCOW" },
        { title: "Moscow Oblast", code: "MOSCOWOBLAST" },
        { title: "Murmansk Oblast", code: "MURMANSKOBLAST" },
        { title: "Nenets Autonomous Okrug", code: "NENETSAUTONOMOUSOKRUG" },
        { title: "Nizhny Novgorod Oblast", code: "NIZHNYNOVGORODOBLAST" },
        { title: "North Ossetia-Alania", code: "NORTHOSSETIA" },
        { title: "Novgorod Oblast", code: "NOVGORODOBLAST" },
        { title: "Novosibirsk Oblast", code: "NOVOSIBIRSKOBLAST" },
        { title: "Omsk Oblast", code: "OMSKOBLAST" },
        { title: "Orenburg Oblast", code: "ORENBURGOBLAST" },
        { title: "Oryol Oblast", code: "ORYOLOBLAST" },
        { title: "Penza Oblast", code: "PENZAOBLAST" },
        { title: "Perm Krai", code: "PERMKRAI" },
        { title: "Primorsky Krai", code: "PRIMORSKYKRAI" },
        { title: "Pskov Oblast", code: "PSKOVOBLAST" },
        { title: "Rostov Oblast", code: "ROSTOVOBLAST" },
        { title: "Ryazan Oblast", code: "RYAZANOBLAST" },
        { title: "Saint Petersburg", code: "SAINTPETERSBURG" },
        { title: "Sakha (Yakutia) Republic", code: "SAKHA" },
        { title: "Sakhalin Oblast", code: "SAKHALINOBLAST" },
        { title: "Samara Oblast", code: "SAMARAOBLAST" },
        { title: "Saratov Oblast", code: "SARATOVOBLAST" },
        { title: "Sevastopol", code: "SEVASTOPOL" },
        { title: "Smolensk Oblast", code: "SMOLENSKOBLAST" },
        { title: "Stavropol Krai", code: "STAVROPOLKRAI" },
        { title: "Sverdlovsk Oblast", code: "SVERDLOVSKOBLAST" },
        { title: "Tambov Oblast", code: "TAMBOVOBLAST" },
        { title: "Tatarstan", code: "TATARSTAN" },
        { title: "Tomsk Oblast", code: "TOMSKOBLAST" },
        { title: "Tula Oblast", code: "TULAOBLAST" },
        {
          title: "Tuva Republic",
          code: "TUVAREPUBLIC",
        },
        { title: "Tver Oblast", code: "TVEROBLAST" },
        { title: "Tyumen Oblast", code: "TYUMENOBLAST" }, {
          title: "Udmurt Republic",
          code: "UDMURTREPUBLIC",
        },
        { title: "Ulyanovsk Oblast", code: "ULYANOVSKOBLAST" },
        { title: "Vladimir Oblast", code: "VLADIMIROBLAST" },
        {
          title: "Volgograd Oblast",
          code: "VOLGOGRADOBLAST",
        }, { title: "Vologda Oblast", code: "VOLOGDAOBLAST" },
        { title: "Voronezh Oblast", code: "VORONEZHOBLAST" }, {
          title: "Yamalo-Nenets Autonomous Okrug",
          code: "YAMALO",
        }, { title: "Yaroslavl Oblast", code: "YAROSLAVLOBLAST" },
        { title: "Zabaykalsky Krai", code: "ZABAYKALSKYKRAI" },
      ],
      form: {
        currency: "",
        accountHolderName: "",
        targetLegalType: "PRIVATE",
      },
      editIndex: null,
      errors: [],
      isSelector: false,
    };
  },
  computed: {
    ...mapGetters([
      "transferWiseConfig",
      "countries",
    ]),
    getSelectedCurrencyConfig() {
      const currenciesConfig = JSON.parse(JSON.stringify(this.transferWiseConfig));
      return this.selectedCurrency ? this._.find(currenciesConfig, { currency: this.selectedCurrency }) : {};
    },
    isAvailable() {
      return Object.keys(this.addresses.transferwise).length > 0;
    },
    isBusiness() {
      return this.form.legalType ? this.form.legalType === "BUSINESS" : (this.form.details) ? this.form.details.legalType === "BUSINESS" : false;
    },
  },
  methods: {
    confirm(data = null) {
      this.confirmHook(data);
      this.close();
    },
    resetForm() {
      this.generateForm();
      this.editIndex = null;
      this.errors = [];
      this.$nextTick(() => this.$refs.observer.reset());
    },
    isRequired(key) {
      return this.required.indexOf(key) !== -1;
    },
    isObject(value) {
      return typeof value === "object";
    },
    format(key) {
      const labels = {
        legalType: "Legal type",
        taxId: "Tax ID",
        accountNumber: "Account number",
        bsbCode: "Bsb code",
        ifscCode: "IFSC Code",
        country: "Country",
        city: "City",
        state: "State",
        postalCode: "Postal code",
        firstLine: "Address",
        abartn: "Routing number",
        bankCode: "Bank code",
        branchCode: "Branch code",
        phoneNumber: "Phone number",
        institutionNumber: "Institution no.",
        transitNumber: "Transit no.",
        rut: "Beneficiary's RUT number (Rol Único Tributario)",
        cardNumber: "UnionPay card number",
        prefix: "Prefix",
        sortCode: "UK Sort code",
        clabe: "Recipient CLABE Number",
        cpf: "Recipient tax ID/CPF",
        swiftCode: "SWIFT code",
        idDocumentType: "ID document type",
        idDocumentNumber: "Beneficiary's identification number",
      };
      return (labels[key]) ? labels[key] : key;
    },
    populateForm(address, index, key) {
      this.errors = [];
      this.selectedCurrency = key;
      this.generateForm();
      address = JSON.parse(JSON.stringify(address));
      this.form = address;
      this.editIndex = index;
    },
    deleteAddress(index, key) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let addresses = { ...this.addresses };
      addresses = JSON.parse(JSON.stringify(addresses));
      addresses.transferwise[key].splice(index, 1);
      const fd = new FormData();
      fd.append("Call", "UPDATE");
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", process.env.VUE_APP_OUTPUT);
      this.$http.post(`${apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.loadAddressBook();
        this.isLoading = false;
        this.$toast.fire("", "Address deleted successfully", "success");
        saveNotification("Address deleted successfully");
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    selectCurrency() {
      this.selectedCurrency = this.form.currency;
      this.generateForm();
      const currencies = ["BDT", "BRL", "CLP", "EGP", "GHS", "HKD", "ILS", "JPY", "KES", "LKR", "MAD",
        "MYR", "NGN", "IDR", "NPR", "PEN", "PHP", "RUB", "SGD", "THB", "VND", "ZAR",
      ];
      if (currencies.indexOf(this.selectedCurrency) !== -1) {
        this.getBanks();
      }
    },
    getCountryCode(currency) {
      const countries = {
        BDT: "BD",
        BRL: "BR",
        CLP: "CL",
        EGP: "EG",
        GHS: "GH",
        HKD: "HK",
        IDR: "ID",
        ILS: "IL",
        JPY: "JP",
        KES: "KE",
        LKR: "LK",
        MAD: "MA",
        MYR: "MY",
        NGN: "NG",
        NPR: "NP",
        PEN: "PE",
        PHP: "PH",
        RUB: "RU",
        SGD: "SG",
        THB: "TH",
        VND: "VN",
        ZAR: "ZA",
      };
      return countries[currency];
    },
    getBanks() {
      const countryCode = this.getCountryCode(this.selectedCurrency);
      axios.get(`https://api.transferwise.com/v1/banks?country=${countryCode}`, {
        headers: {
          Authorization: "Bearer d11c23bb-a3cb-4285-bc57-d51858ef8bdf",
        },
      }).then((result) => {
        this.banks = result.data.values;
      }).catch(error => {
        console.log(error);
      });
    },
    getBranches() {
      const bankCode = this.form.details.bankCode;
      const countryCode = this.getCountryCode(this.selectedCurrency);
      const currencies = ["BDT", "JPY", "LKR", "VND"];
      if (currencies.indexOf(this.selectedCurrency) !== -1) {
        axios.get(`https://api.transferwise.com/v1/bank-branches?country=${countryCode}&bankCode=${bankCode}`, {
          headers: {
            Authorization: "Bearer d11c23bb-a3cb-4285-bc57-d51858ef8bdf",
          },
        }).then((result) => {
          this.branches = result.data.values;
        }).catch(error => {
          console.log(error);
        });
      }
    },
    generateForm() {
      this.$nextTick(() => this.$refs.observer.reset());
      let config = this.getSelectedCurrencyConfig;
      config = JSON.stringify(config);
      config = JSON.parse(config);
      if (config && typeof config !== "undefined") {
        this.required = config.required.split(",");
        delete config.required;
        config.accountHolderName = "";
        config.targetLegalType = "PRIVATE";
        config = JSON.stringify(config);
        config = JSON.parse(config);
        this.form = config;
      }
    },
    toggleAsDefault(address, key) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let addresses = { ...this.addresses };
      addresses = JSON.parse(JSON.stringify(addresses));
      let message = "Address marked as default successfully";
      Object.keys(addresses.transferwise).forEach((currency) => {
        addresses.transferwise[currency] = addresses.transferwise[currency].map((addr, i) => {
          const index = this._.findIndex(addresses.transferwise[currency], address);
          if (index !== -1 && i === index) {
            addr.default = !addr.default;
            if (!addr.default) {
              message = "Address removed as default successfully";
            }
          } else {
            addr.default = false;
          }
          return addr;
        });
      });
      const fd = new FormData();
      fd.append("Call", "UPDATE");
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.loadAddressBook();
        this.isLoading = false;
        this.$toast.fire("", message, "success");
        saveNotification(message);
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    selectAddress(address) {
      this.confirm(address);
    },
    loadAddressBook() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        POSDATA: "",
        Call: "SHOW",
        KeyData: "AddressBook_" + this.user.ID,
        MerchantID: this.user.ID,
        APIKey: this.user.APIKey,
        output: "json",
      };
      this.$http.get(`${apiBaseUrl}/MarketAPI`, {
        params: payload,
      }).then(response => {
        if (response.data !== "nothing found") {
          this.addresses = response.data;
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    generateCompare(currency) {
      let compare = {
        currency: currency,
      };
      this.required.forEach(key => {
        compare = Object.assign(compare, {
          details: {
            [key]: this.form.details[key],
          },
        });
      });
      return compare;
    },
    onSubmit() {
      const validateObj = (obj) => {
        this._(obj).forEach((value, key, objRef) => {
          if (typeof value === "object") {
            validateObj(objRef[key]);
          } else {
            if (typeof value === "string") {
              if (value === "") {
                this.errors.push(key);
              }
            }
          }
        });
      };
      validateObj(this.form);
      if (this.errors.length > 0) {
        return;
      }
      this.isLoading = true;
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let addresses = { ...this.addresses };
      addresses = JSON.parse(JSON.stringify(addresses));
      const action = Object.keys(addresses.crypto).length > 0 ||
        Object.keys(addresses.transferwise).length > 0 ? "UPDATE" : "INSERT";
      const data = this.form;
      if (typeof addresses.transferwise[this.selectedCurrency] === "undefined") {
        addresses.transferwise[this.selectedCurrency] = [];
      }
      const compare = this.generateCompare(this.selectedCurrency);
      const index = this._.findIndex(addresses.transferwise[this.selectedCurrency], compare);
      if (index !== -1) {
        addresses.transferwise[this.selectedCurrency][index] = data;
      } else if (this.editIndex !== null) {
        addresses.transferwise[this.selectedCurrency][this.editIndex] = data;
        this.editIndex = null;
      } else {
        data.default = false;
        addresses.transferwise[this.selectedCurrency].push(data);
      }
      const fd = new FormData();
      fd.append("Call", action);
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.resetForm();
        this.loadAddressBook();
        this.isLoading = false;
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    clickOverlay() {
      // Hide modal
      this.confirm();
    },
    close() {
      $("#bsModalBankAddressBook").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("bank-address-book-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.isSelector = args.isSelector || false;
      this.confirmHook = args.confirm;
      $("#bsModalBankAddressBook").modal("show");
      if (this.user && this.user.AddressBook !== "NA") {
        this.addresses = JSON.parse(this.user.AddressBook);
      }
    });
  },
};
</script>

<style scoped></style>
