<template>
  <div id="bsModalCryptoAddressBook" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
    data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1" @click="clickOverlay()">
    <div class="modal-dialog" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="currency" rules="required" tag="div">
                <label for="currency">Currency</label>
                <select id="currency" v-model="form.altCoinID" class="form-control">
                  <option v-for="(coin, index) in activeAltCoins" :key="index" :value="coin.ID">
                    {{ coin.Name + ' ' + coin.LongName.toUpperCase() }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="address" rules="required" tag="div">
                <label for="address">Address</label>
                <input id="address" v-model="form.address" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="description" rules="required" tag="div">
                <label for="description">Description</label>
                <textarea id="description" v-model="form.description" class="form-control" rows="4"></textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-if="isTagEnabled" v-slot="{ errors }" class="form-group" name="tag" tag="div">
                <label for="description">Tag</label>
                <input id="tag" v-model="form.tag" class="form-control" type="text" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  Save
                  <span v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin ml-8"></i>
                  </span>
                </button>
              </div>
            </form>
          </ValidationObserver>
          <div class="table-responsive">
            <table class="table table-striped table-condensed mb-0 mt-16">
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Address</th>
                  <th>Tag</th>
                  <th>Description</th>
                  <th style="min-width: 90px;"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(coinAddresses, key) in addresses.crypto">
                  <template v-for="(address, index) in coinAddresses">
                    <tr :key="key + '-' + index + '-content'">
                      <td>{{ getCoinNameById(address.altCoinID) }}</td>
                      <td>{{ address.address }}</td>
                      <td>{{ address.tag }}</td>
                      <td>{{ address.description }}</td>
                      <td class="md-actions">
                        <a v-tooltip="{ content: address.default ? 'Remove as default' : 'Select as default', trigger: 'click hover focus' }"
                          v-if="!isSelector" class="nav-action mr-4" @click="toggleDefault(index, key)">
                          <svg :stroke="address.default ? '#a94442' : '#3c763d'" class="w-24 h-24" fill="none"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </svg>
                        </a>
                        <a v-if="isSelector" class="nav-action mr-4" @click="selectAddress(address)">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-24 h-24">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </a>
                        <a class="nav-action mr-4" @click="populateForm(index, key)">
                          <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                            <path
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                            </path>
                          </svg>
                        </a>
                        <a class="nav-action danger" @click="deleteAddress(index, key)">
                          <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                            <path
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                            </path>
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveNotification } from "../../../lib/utils";
import * as _ from "lodash";

export default {
  name: "CryptoAddressBookModal",
  data() {
    return {
      isLoading: false,
      title: "",
      user: null,
      addresses: {
        crypto: {},
        transferwise: {},
      },
      form: {
        address: "",
        tag: "",
        description: "",
        altCoinID: "",
        default: false,
      },
      editIndex: null,
      isSelector: false,
      apiBaseUrl: process.env.VUE_APP_API_URL,
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "onlineAltCoins",
    ]),
    isTagEnabled() {
      if (this.form.altCoinID) {
        const coin = _.find(this.activeAltCoins, { ID: this.form.altCoinID });
        if (coin !== null) {
          return coin.Tag === 1;
        }
      }
      return false;
    },
    activeAltCoins() {
      const activeAltCoins = [];
      if (this.user && Object.keys(this.user).length > 0) {
        const selectedAltCoins = this.user.SelectedAltCoins.split(",");
        this.onlineAltCoins.forEach(coin => {
          if (selectedAltCoins.indexOf(coin.ID.toString()) !== -1) {
            activeAltCoins.push(coin);
          }
        });
      }
      return this._.orderBy(activeAltCoins, [coin => coin.Name.toLowerCase()], "asc");
    },
  },
  methods: {
    confirm(address = null) {
      delete address.default;
      this.confirmHook(address);
      this.close();
    },
    reset() {
      this.form = {
        address: "",
        tag: "",
        description: "",
        altCoinID: "",
        default: false,
      };
      this.$nextTick(() => this.$refs.observer.reset());
    },
    populateForm(index, key) {
      const address = this.addresses.crypto[key][index];
      this.form.address = address.address;
      this.form.tag = address.tag ? address.tag : "";
      this.form.altCoinID = address.altCoinID;
      this.form.description = address.description;
      this.form.default = address.default ? address.default : false;
      this.editIndex = index;
    },
    getCoinNameById(id) {
      const coin = this._.find(this.onlineAltCoins, { ID: id });
      if (coin) {
        return coin.Name;
      }
      return "";
    },
    selectAddress(address) {
      this.confirm(address);
    },
    deleteAddress(index, key) {
      const addresses = { ...this.addresses };
      addresses.crypto[key].splice(index, 1);
      const fd = new FormData();
      fd.append("Call", "UPDATE");
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${this.apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.isLoading = false;
        this.confirmHook();
        this.$toast.fire("", "Address deleted successfully", "success");
        saveNotification("Address deleted successfully");
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    toggleDefault(index, key) {
      Object.keys(this.addresses.crypto).forEach(i => {
        this.addresses.crypto[i].forEach((address, j) => {
          if (i === key && j === index) {
            if (typeof this.addresses.crypto[i][j].default === "undefined") {
              this.addresses.crypto[i][j].default = false;
            }
            this.addresses.crypto[i][j].default = !this.addresses.crypto[i][j].default;
          } else {
            this.addresses.crypto[i][j].default = false;
          }
        });
      });
      const fd = new FormData();
      fd.append("Call", "UPDATE");
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(this.addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${this.apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.$toast.fire("", "Updated successfully", "success");
      }).catch(error => {
        console.log(error.response);
      });
    },
    onSubmit() {
      this.isLoading = true;
      const addresses = JSON.parse(JSON.stringify(this.addresses));
      const action = Object.keys(addresses.crypto).length > 0 ||
        Object.keys(addresses.transferwise).length > 0 ? "UPDATE" : "INSERT";
      const data = this.form;
      if (typeof addresses.crypto[this.form.altCoinID] === "undefined") {
        addresses.crypto[this.form.altCoinID] = [];
      }
      const index = window._.findIndex(addresses.crypto[this.form.altCoinID], {
        address: this.form.address,
      });
      if (index !== -1) {
        addresses.crypto[this.form.altCoinID].splice(index, 1, data);
      } else if (this.editIndex !== null) {
        addresses.crypto[this.form.altCoinID].splice(this.editIndex, 1, data);
        this.editIndex = null;
      } else {
        addresses.crypto[this.form.altCoinID].push(data);
      }
      const fd = new FormData();
      fd.append("Call", action);
      fd.append("KeyData", "AddressBook_" + this.user.ID);
      fd.append("ValueData", JSON.stringify(addresses));
      fd.append("MerchantID", this.user.ID);
      fd.append("APIKey", this.user.APIKey);
      fd.append("output", "json");
      this.$http.post(`${this.apiBaseUrl}/MarketAPI?POSDATA`, fd, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          this.reset();
          this.confirmHook();
          this.addresses = addresses;
        }
      }).catch(error => {
        console.log(error.response);
        this.isLoading = false;
      });
    },
    clickOverlay() {
      // Hide modal
      this.confirm();
    },
    close() {
      $("#bsModalCryptoAddressBook").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("crypto-address-book-modal", (args) => {
      this.title = args.title;
      this.user = args.data || null;
      this.isSelector = args.isSelector || false;
      this.confirmHook = args.confirm;
      $("#bsModalCryptoAddressBook").modal("show");
      if (this.user && this.user.AddressBook !== "NA") {
        this.addresses = JSON.parse(this.user.AddressBook);
      }
    });
  },
};
</script>

<style scoped></style>
